import { useState, useContext } from "react";
import { Form, Input, Button, Modal } from "antd"
import logo from "../../assets/icon/logo.svg";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../component/Account/Account";
import styles from "./Login.module.scss";



function Login() {
    const [formValue, setFormValue] = useState({})
    const [form] = Form.useForm();
    const { authenticate } = useContext(AccountContext);
    const navigate = useNavigate();

    const onSubmit = async () => {
        const { email, password } = formValue
        try {
            const res = await authenticate(email,password)
            navigate("/notification")
        } catch (e) {
            console.log(e);
            Modal.error({
                title: 'Error',
                content: e,
                centered: true
            });
        }
    };

    const redirect = () => {
        navigate("/")
    }
    return (
        <>
            <header className="App-header">
                <img src={logo} className={styles.logo} alt="logo" />
                <button className={styles.signUp} onClick={redirect}>Sign Up</button>
            </header>
            <main className={styles.body}>
                <h2 className={styles.title}>Log in</h2>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    onValuesChange={(_, allFields) => {
                        setFormValue(allFields)
                    }}
                    autoComplete="off"
                    className={styles.formLogin}
                >
                    <Form.Item
                        className={styles.inputContainer}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input placeholder="Email" className={styles.input} />
                    </Form.Item>

                    <Form.Item
                        className={styles.inputContainer}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" className={styles.input} />
                    </Form.Item>
                    <Form.Item
                        shouldUpdate
                    >
                        {() => (
                            <Button
                                className={styles.sentBtn}
                                htmlType="submit"
                            >
                                Log in
                            </Button>
                        )}

                    </Form.Item>
                </Form>
            </main>
        </>
    );
}

export default Login;
