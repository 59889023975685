import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Modal } from "antd"
import logo from "../../assets/icon/logo.svg";
import UserPool from "../../UserPool";
import styles from "./SignUp.module.scss";


function Authorize() {
    const [formValue, setFormValue] = useState({})
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onSubmit = () => {
        const { email, password } = formValue;
        UserPool.signUp(email, password, [], null, (err, data) => {
            if (err) {
                Modal.error({
                    title: 'Error',
                    content: err.message,
                    centered: true
                });
            } else {
                navigate("/login")
                // console.log(data);
            }
        });
    };

    const redirect = () => {
        navigate("/login")
    }

    return (
        <>
            <header className="App-header">
                <img src={logo} className={styles.logo} alt="logo" />
                <button className={styles.login} onClick={redirect}>Login</button>
            </header>
            <main className={styles.body}>
                <h2 className={styles.title}>Sign Up</h2>
                <Form
                    form={form}
                    onFinish={onSubmit}
                    onValuesChange={(_, allFields) => {
                        setFormValue(allFields)
                    }}
                    autoComplete="off"
                    className={styles.formLogin}
                >
                    <Form.Item
                        className={styles.inputContainer}
                        name="email"
                        a
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input placeholder="Email" className={styles.input} />
                    </Form.Item>

                    <Form.Item
                        className={styles.inputContainer}
                        name="password"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" className={styles.input} />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm password" className={styles.input} />
                    </Form.Item>
                    <Form.Item
                        shouldUpdate
                    >
                        {() => (
                            <Button
                                className={styles.sentBtn}
                                htmlType="submit"
                            >
                                Sign up
                            </Button>
                        )}

                    </Form.Item>
                </Form>
            </main>
        </>
    );
}

export default Authorize;
