import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {Account}  from "./component/Account/Account";
import NewNotification from "./pages/NewNotification/NewNotification";
import SignUp from "./pages/SignUp/SignUp"
import Login from "./pages/LogIn/LogIn";




function App() {
  return (
    <div className="App">
    <Account>
      <div className="container">
        <Router>
          <Routes>
            <Route path="/" element={<SignUp />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/notification" element={<NewNotification />}></Route>
          </Routes>
        </Router>
      </div>
    </Account>
    </div>
  );
}

export default App;
