import { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../component/Account/Account";
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import { Form, Input, Button, notification } from 'antd'
import { useNavigate } from "react-router-dom";
import logo from "../../assets/icon/logo.svg";
import userIcon from "../../assets/icon/iconsUser.png";
import styles from "./NewNotification.module.scss";

const { TextArea } = Input;
const { REACT_APP_API_URL } = process.env

function NewNotification() {
    const [status, setStatus] = useState("");
    const [formValue, setFormValue] = useState({});
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { getSession, logout } = useContext(AccountContext);
    const handleSubmit = async () => {
        try {
            const res = await fetch(`${REACT_APP_API_URL}/notifications/web`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formValue),
            })
            notification.open({
                message: "Notification added",
                icon: (
                    <SmileOutlined style={{ color: '#55c42a' }} />
                ),
                placement:"bottomRights"
            })
            form.resetFields()
        } catch (error) {
            console.log(error.message);
            notification.error({
                message: "Something went wrong",
                description:error.message,
                icon: (
                    <FrownOutlined style={{ color: 'red' }} />
                ),
                placement:"bottomRights"

            })
        }

    };
    useEffect(() => {
        getSession()
            .then(session => {
                setStatus(true)
            })
            .catch((err) => {
                navigate("/login")
            });
    }, [])
    const logOut = () => {
        logout();
        navigate("/login");
    }
    return (
        <>
            <header className="App-header">
                <img src={logo} className={styles.logo} alt="logo" />
                <div className={styles.userWrap} onClick={logOut}>
                    <p className={styles.nameSurname}>{status && "Log out"}</p>
                    <img src={userIcon} className={styles.userICon} alt="user" />
                </div>
            </header>
            <main className={styles.formContainer}>
                <Form
                    form={form}
                    className={styles.form}
                    onValuesChange={(_, allFields) => {
                        setFormValue(allFields)
                    }}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: ""
                            }
                        ]}
                    >
                        <Input className={styles.title} placeholder="Title of notification" />
                    </Form.Item>
                    <Form.Item
                        name='message'
                        rules={[
                            {
                                required: true,
                                message: ""
                            }
                        ]}  >
                        <TextArea className={styles.textarea} rows={8} placeholder="Write a text" />
                    </Form.Item>
                    <Form.Item
                        shouldUpdate
                    >
                        {() => (
                            <Button
                                htmlType="submit"
                                disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form
                                        .getFieldsError()
                                        .filter(({ errors }) => errors.length).length}
                                className={styles.sentBtn}
                            >
                                Send
                            </Button>
                        )}

                    </Form.Item>
                </Form>
            </main>
        </>
    );
}

export default NewNotification;
